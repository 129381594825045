html, body {
    height: 100%;
}

#root {
    height: 100%;
}

.hidden input[type="date"] {
    color: transparent;
}

